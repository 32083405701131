<template>
    <div>
      <!-- Fixebar avec NavbarWeb et FilterWeb -->
      <div class="fixebar">
        <NavbarWeb2 msg="Welcome to Your Vue.js App" style="border-bottom: 5px solid #00d1b2; padding-bottom: 20px"/>
      </div>

      <div style="margin-top: 100px">
      <FilterWeb msg="Welcome to Your Vue.js App"/>
      </div>
      <!-- ListeWeb -->
      <div class="list-container">
        <ListeWeb :msg="datas"/>
        <el-pagination
          style="margin-bottom: 70px; width: 500px; margin: 0 auto; display: flex; justify-content: center; padding: 15px"
          :current-page="biens.rpage"
          :page-size="10"
          size="large"
          :page-count="biens.total_pages"
          layout="prev, pager, next"
          @current-change="handlePageChange"
          background
        ></el-pagination>
      </div>
    </div>
  </template>
  
  <script>
  import { ElLoading } from 'element-plus'
  //import NavbarWeb from '@/components/accueil/accueil_web/Navbar.vue'
  import NavbarWeb2 from '@/components/accueil/accueil_web/Navbarr.vue'
  import FilterWeb from '@/components/accueil/accueil_web/Filter.vue'
 // import ImageBan from '@/components/accueil/accueil_web/Image-ban.vue'
  import ListeWeb from '@/components/accueil/accueil_web/Liste.vue'
  
  export default {
    name: 'AccueilIndex',
    components: {
      NavbarWeb2,
      FilterWeb,
      ListeWeb,
     // ImageBan
    },
    computed: {
      biens() {
        return this.$store.state.biens;
      },
    },
    data() {
      return {
        datas: [],
        value1: [1, 80],
        select1: '0',
        select2: '0',
        selectedType: '0',
        filters: {
                piscine: '',
                restaurant: '',
                bordureMer: '',
                evenement: '',
                internet: '',
            },
        // Ajoutez d'autres données si neccessaire
      };
    },
    methods: {
      ListeFiltre2() {
      const loading = ElLoading.service({
          lock: true,
          text: 'Loading...',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      const postData = {
        prix_min: this.value1[0] * 5000,
        prix_max: this.value1[1] * 5000,
        type: this.selectedType,
        ville: this.biens.rville,
        parpage: 20,
        commune: this.biens.rquartier,
        piscine: this.filters.piscine ? 1 : 0,
        restaurant: this.filters.restaurant ? 1 : 0,
        bordureMer: this.filters.bordureMer ? 1 : 0,
        evenement: this.filters.evenement ? 1 : 0,
        internet: this.filters.internet ? 1 : 0
      };

      if (postData.prix === 'Tarif') {
        postData.prix = '0';
      }

      if (postData.type === 'Types') {
        postData.type = '0';
      }

      const randomId = Math.floor(Math.random() * 1000000);
      this.$axios.post('/web_liste_appart.php?page='+ this.biens.rpage+'&id='+ randomId, postData)
        .then(response => {
          this.searchOk = true;
          this.datas= response.data.data;
          let data = {
            datas: response.data.data,
            total_row: response.data.total_rows,
            total_pages: response.data.total_pages,
            rtype: this.biens.rtype,
            rville: this.biens.rville,
            rquartier: this.biens.rquartier,
            rpage: this.biens.rpage
          }
          this.$store.commit('setDatas', data);
          let dons = response.data.data[0];
          this.$store.commit("setDonneesBien", dons);
        })
        .catch(error => {
          console.error('Erreur de requête:', error);
        });
        loading.close();
    },

        handlePageChange(page) {
          this.$store.commit('setBiensPage', page);
          this.biens.rpage = page;
          this.ListeFiltre2();
          this.scrollToTop();
          //this.$router.go();
        },

        scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth' // Permet un défilement fluide
			});
		},
    },
    mounted() {
        this.ListeFiltre2();
    }
  };
  </script>
  
  <style scoped>
  /* Styles spécifiques au composant */
  .fixebar {
    position: fixed;
    width: 100%;
    box-shadow: 0 .5px 1px rgba(0,0,0,0.1);
    top: 0;
    left: 0;
    z-index: 1000; /* Plus haut z-index pour rester au-dessus */
  }
  
  .list-container {
    margin-top: 20px; /* Ajustement de la marge pour laisser de la place à fixebar */
    position: relative; /* Position relative pour ajuster le z-index */
    z-index: 900; /* Z-index inférieur à celui de fixebar */
  }
  </style>
  