import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Accueil.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Accueil
  },
  {
    path: '/detail/:param',
    name: 'detail',
    component: () => import('../views/Detail.vue')
  },
  {
    path: '/summary',
    name: 'summary',
    component: () => import('../components/detail/detail_mobile/SummaryPage.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/dashboard/dashboard_login/LoginIndex.vue')
  },
  {
    path: '/login_web',
    name: 'login_web',
    component: () => import('../components/dashboard/dashboard_login/LoginWeb.vue')
  },
  {
    path: '/inscription',
    name: 'inscription',
    component: () => import('../components/dashboard/dashboard_login/InscriptionIndex.vue')
  },
  {
    path: '/inscription_web',
    name: 'inscription_web',
    component: () => import('../components/dashboard/dashboard_login/InscriptionWeb.vue')
  },

  {
    path: '/nouvelle_enregistrement',
    name: 'nouvelle_enregistrement',
    component: () => import('../views/dashboard/Formulaire.vue')
  },
  // DASHBOARD //
  {
    path: '/dashboard_mobile',
    name: 'dashboard_mobile',
    component: () => import('../components/dashboard/dashboard_menu/MenuIndex.vue')
  },
  {
    path: '/dashboard_w',
    name: 'dashboard_w',
    component: () => import('../components/dashboard_web/dashboard.vue')
  },
  {
    path: '/reservation_u_w',
    name: 'reservation_u_w',
    component: () => import('../components/dashboard_web/reservations.vue')
  },
  {
    path: '/reservation_a_w',
    name: 'reservation_a_w',
    component: () => import('../components/dashboard_web/reservation_admin.vue')
  },
  {
    path: '/residences_w',
    name: 'residences_w',
    component: () => import('../components/dashboard_web/residences.vue')
  },
  {
    path: '/dashboard_residence',
    name: 'dashboard_residence',
    component: () => import('../components/dashboard/dashboard_residence/ResidenceIndex.vue')
  },
  {
    path: '/dashboard_mes_favoris',
    name: 'dashboard_mes_favoris',
    component: () => import('../components/dashboard/dashboard_residence/MesFavoris.vue')
  },
  {
    path: '/dashboard_reservation',
    name: 'dashboard_reservation',
    component: () => import('../components/dashboard/dashboard_reservation/ReservationIndex.vue')
  },
  {
    path: '/dashboard_mes_reservations',
    name: 'dashboard_mes_reservations',
    component: () => import('../components/dashboard/dashboard_reservation/MesReservationsIndex.vue')
  },
  {
    path: '/dashboard_paiement',
    name: 'dashboard_paiement',
    component: () => import('../components/dashboard/dashboard_paiement/PaiementIndex.vue')
  },
  {
    path: '/dashboard_quartier',
    name: 'dashboard_quartier',
    component: () => import('../components/dashboard/Quartier.vue')
  },
  {
    path: '/dashboard_parametre',
    name: 'dashboard_parametre',
    component: () => import('../components/dashboard/dashboard_parametre/ParametreIndex.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
