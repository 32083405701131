<template>
  <div>
    <div style="width: 100%;">
      <nav class="navbar">
        <div class="py-0" style="margin-left: -10px;">
          <div>
              <div class="position-fixed z-3 w-100 top-0" style="display: flex; align-items: center; justify-content: space-between;">
                  <span class="mt-2 mb-1 ml-4">
                      <router-link to="/" style="margin-left: 10px">
                          <span class="sarezide has-text-primary">Sa</span>
                          <span class="sarezide">Rezide</span>
                      </router-link>
                  </span>
                  <span class="mt-2 mb-1 mr-4">
                      <router-link to="/help-center" title="Se connecter">
                          <i class="fa-solid fa-circle-question" style="font-size: 1.5rem; color: #050a30;"></i>
                      </router-link>
                      <el-badge :value="0" class="ml-4">
                          <i class="fa-solid fa-bell" style="font-size: 1.5rem; color: #050a30;"></i>
                      </el-badge>
                  </span>
              </div>
          </div>
      </div>

        <div class="navbar-menu" :class="{'is-active': isActive}" style="margin-left: 25px">
          <div class="navbar-start centered-navbar-start">
            <div class="field has-addons" style="margin-top: 10px;width: 100%; max-width: 900px;">
              <p class="control">
                <a class="button is-static" style="background: white">
                  Destination
                </a>
              </p>
              <el-select
                v-model="destination"
                filterable
                remote
                reserve-keyword
                placeholder="Please enter a keyword"
                :remote-method="remoteMethod"
                :loading="loading"
                style="width: 440px"
                size="large"
                @blur="onDestinationBlur"
              >
                <el-option
                  v-for="item in options"
                  :key="item.code"
                  :label="item.ville"
                  :value="item.ville"
                />
              </el-select>
              <el-date-picker
                ref="datePicker"
                style="margin-left: -10px;"
                v-model="datePeriode"
                type="daterange"
                range-separator="Au"
                start-placeholder="Date début"
                end-placeholder="Date Fin"
                size="large"
              />
            </div>
          </div>

          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <router-link class="navbar-item" href="#" to="/nouvelle_enregistrement">Ajouter ma résidence</router-link>
                <a class="button is-light">
                  Connexion
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccueilNavbar',
  data() {
    return {
      destination: '',
      datePeriode: [],
      isActive: false,
      options: [{
          ville: 'Abidjan',
          code: 'Tom'
        }, {
          ville: 'Assinie',
          code: 'Tom'
        }, {
          ville: 'Bassam',
          code: 'Tom'
        }, {
          ville: 'Bouaké',
          code: 'Tom'
        }],
    };
  },
  methods: {
    toggleNavbar() {
      this.isActive = !this.isActive;
    },
    onDestinationBlur() {
      if (this.destination) {
        this.$refs.datePicker.focus();
      }
    }
  }
};
</script>

<style scoped>
/* Styles spécifiques au composant */
.navbar {
  top: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 1000;
  background: white;
  padding: 0px;
}
.navbar-item img {
  width: 200px;
}
.responsive-input {
  max-width: 500px;
  width: 100%;
}
.centered-navbar-start {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
