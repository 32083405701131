<template>
    <div class="summary-page">
      <div class="page-header">
        <p class="title-detail" style="font-family: 'Montserrat', sans-serif; font-size: 20px"></p>
        <button class="button is-rounded is-small close-button" @click="closeLogin">
          <span class="icon">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </div>
  
      <!-- Bloc 2 : Séjour -->
      <div class="block" style="margin-top: -1px">
        <h3 class="title is-5" style="text-align: center; font-size: 30px">Inscription</h3>
        <p style="text-align: center;margin-top: -10px">J'ai déjà un compte, <el-link @click="LoginPage()" type="primary" style="font-size: 19px">se connecter</el-link></p>
        <div style="margin-top:30px">
          
            <div class="field">
                <div class="control has-icons-left has-icons-right">
                  <input
                  v-model="login.nom"
                    class="input"
                    type="text"
                    placeholder="Nom"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
            </div>

            <div class="field">
                <div class="control is-large has-icons-left has-icons-right">
                  <input
                  v-model="login.prenom"
                    class="input"
                    type="text"
                    placeholder="Prenom"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
            </div>

            <div class="field">
                <div class="control is-large has-icons-left has-icons-right">
                  <input
                  v-model="login.contact"
                    class="input"
                    type="text"
                    placeholder="Contact"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-phone"></i>
                  </span>
                </div>
            </div>

            <br/>

            <div class="field">
                <div class="control is-large has-icons-left has-icons-right">
                  <input
                  v-model="login.email"
                    class="input"
                    type="text"
                    placeholder="Email"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <span class="icon is-small is-right">
                    <i class="fas fa-check"></i>
                  </span>
                </div>
            </div>
  
            <div class="field">
                <p class="control has-icons-left">
                <input v-model="login.password" class="input" type="password" placeholder="Mot de passe" />
                <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                </span>
                </p>
            </div>

            <div class="field">
                <p class="control has-icons-left">
                <input v-model="login.passwordverif" class="input" type="password" placeholder="Mot de passe confirmation" />
                <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                </span>
                </p>
            </div>
  
        </div>
      </div>
  
      <div style="width: 100%">
        <button style="width: 100%" class="button is-primary is-fullwidth" @click="verif()">M'inscrire</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ElLoading, ElNotification } from 'element-plus'
  export default {
    name: 'SummaryPage',
    props: {
      web: {
        type: Number, // Indique si la propriété est requise
      }
    },
    data() {
      return {
        login: {
            email: '',
            password: '',
            nom: '',
            prenom: '',
            contact: '',
            passwordverif: '',
        },
        decryptedData: null,
        reserve : {
          reference: '',
          nom: '',
          prenom: '',
          contact: '',
          email: '',
          du: '',
          au: '',
          nbjour: 0,
          avance: 0,
          residence: 0,
          paiement: '',
          nbper: 2,
          nbenf: 1,
          taxi: 'Non',
          pays: 'Côte d\'Ivoire',
          statut_reserve: 'En cours',
          commentaire_reserve: '',
          user_reserve: 0,
          proprietaire: 0
        },
        showDatePicker: false,
        date: null,
        activeDate: false,
        invite: 1,
        dateArrivee: '', // Pour stocker la date d'arrivée sélectionnée
        dateDepart: '', // Pour stocker la date de départ sélectionnée
        nombrePersonnes: 1, // Pour stocker le nombre de personnes, par défaut 1
        formattedDate: '', // Pour stocker la date formatée
      };
    },
    computed: {
      rechercheInfos() {
        return this.$store.state.rechercheInfos;
      },
      biens() {
        return this.$store.state.donneesBiens; // Assurez-vous que donneesBiens contient les détails nécessaires
      },
      periode() {
        // Exemple de calcul de la période (à ajuster selon votre logique)
        return `${this.dateArrivee} au ${this.dateDepart}`;
      },
      calculerTotal() {
        // Méthode pour calculer le total en fonction de la période sélectionnée
        // Vous pouvez implémenter votre logique de calcul ici
        return this.biens.tarif_jour; // Exemple simplifié, ajustez selon votre logique réelle
      }
    },
    watch: {
      date: {
        handler() {
          this.updateFormattedDate();
        },
        deep: true,
      },
    },
    methods: {
        verif() {
            if (this.login.password === this.login.passwordverif) {
                this.submitLogin();
            } else {
                ElNotification({
                title: 'Erreur',
                message: 'Verifier tous les champs',
                type: 'error',
                });
            }
        },
        async submitLogin() {
        const loading = ElLoading.service({
            lock: true,
            text: 'Loading...',
            background: 'rgba(0, 0, 0, 0.7)',
        });

        try {
            // Envoie des données de connexion au serveur
            const response = await this.$api.postInscription({ email: this.login.email, password: this.login.password, nom: this.login.nom, prenom: this.login.prenom, contact: this.login.contact });

            if (response.data.valide === 1) {

            ElNotification({
                title: 'Succès',
                message: response.data.mes || 'Connexion réussie',
                type: 'success',
            });

            this.$store.commit('ConnexionAgent', this.userData);
            this.$router.push({ path: '/dashboard_mobile' });
            } else {
            ElNotification({
                title: 'Erreur',
                message: response.data.mes || 'Erreur',
                type: 'error',
            });
            }
        } catch (error) {
            console.error('Erreur de requête:', error);
        } finally {
            loading.close();
        }
        },
        goToLogin() {
        this.$router.push({ path: '/login' });
      },
      generateReservationReference() {
        const date = new Date();
        
        // Format de la date : aammjj (année, mois, jour)
        const year = String(date.getFullYear()).slice(-2); // Les deux derniers chiffres de l'année
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois (ajouter +1 car getMonth() est 0-indexed)
        const day = String(date.getDate()).padStart(2, '0'); // Jour
        
        // Ajouter l'heure et la minute
        const hours = String(date.getHours()).padStart(2, '0'); // Heure
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Minute
        
        // Générer une partie unique/aléatoire
        const uniquePart = Date.now().toString().slice(-6); // Par exemple, les 6 derniers chiffres du timestamp

        // Combiner la date, l'heure, la minute et la partie unique
        const reference = `${year}${month}${day}${hours}${minutes}${uniquePart}`;
        
        return reference;
      },
      retourPagePrecedente() {
        // Méthode pour retourner à la page précédente
        this.$router.go(-1); // Utilisation de Vue Router pour revenir en arrière
      },
      LoginPage() {
        let statut = true;
        this.$store.commit('setLoginPage', statut);
      },
      closeLogin() {
        let statut = false;
        this.$store.commit('setLogin', statut);
      },
      scrollToTop() {
          window.scrollTo({
            top: 0,
            behavior: 'smooth' // Permet un défilement fluide
          });
        },
      formatMoney(amount) {
        if (isNaN(amount)) {
          return amount;
        }
        return new Intl.NumberFormat('fr-FR', {
          style: 'currency',
          currency: 'XOF'
        }).format(amount);
      },
      updateFormattedDate() {
        if (this.rechercheInfos.du && this.rechercheInfos.au) {
          const startDate = new Date(this.rechercheInfos.du);
          const endDate = new Date(this.rechercheInfos.au);
          const options = { day: '2-digit', month: 'short' };
  
          if (startDate.getMonth() === endDate.getMonth()) {
            this.formattedDate = `${startDate.getDate()}-${endDate.toLocaleDateString('fr-FR', options)}`;
          } else {
            const startOptions = { day: '2-digit', month: 'short' };
            const endOptions = { day: '2-digit', month: 'short' };
            this.formattedDate = `${startDate.toLocaleDateString('fr-FR', startOptions)} - ${endDate.toLocaleDateString('fr-FR', endOptions)}`;
          }
        } else {
          this.formattedDate = '';
        }
        this.activeDate = false;
      },
      closeDatePopover() {
        this.$refs.datePopover.$emit('close');
      }
    },
    mounted() {
      this.nombrePersonnes = this.rechercheInfos.adulte + this.rechercheInfos.enfant;
      this.updateFormattedDate(); // Mettre à jour la date formatée lors du montage
    }
  };
  </script>
  
  <style scoped>
.summary-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Pour permettre au bouton d'être positionné en absolu */
}

.page-header {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px; /* Ajuste l'espacement si nécessaire */
}

.block {
  margin-top: 100px; /* Ajuste la position du contenu */
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px; /* Limite la largeur du contenu si nécessaire */
}

.divider {
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.list-unstyled li {
  margin-bottom: 0.5rem;
}

.field {
  margin-bottom: 1rem;
  width: 100%;
}
</style>