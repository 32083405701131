<template>
  <div>
    <!-- Fixebar avec NavbarWeb et FilterWeb -->
    <div class="fixebar">
      <NavbarMobile msg="Welcome to Your Vue.js App"/>
    </div>
    
    <div class="list-container">
      <div class="accueil-bodyimg-themea_mobile rounded-2" style="margin: 12px; border-radius: 10px">
        <div style="padding: 10px;">
            <center class="mt-5" style="margin-top: 3px !important;">
            
              <h5 class="accueil-bodysubtitle-themea">Plateforme de réservation de résidences meublées et d'hôtel en Côte d'Ivoire. 
              </h5>
              <Searchbar/> 
            </center>
            
        </div>
      </div>
      <div class="results-toolbar" v-if="searchOk">
        <span class="results-count">Résultats : <b>{{ biens.total_row }}</b> - page {{ biens.rpage }}-{{ biens.total_pages }}</span>
        <div class="view-buttons">
          <button class="button" @click="gridChange(false)">
            <span class="icon is-small">
              <i class="fa-solid fa-bars"></i>
            </span>
          </button>
          <button class="button" @click="gridChange(true)">
            <span class="icon is-small">
              <i class="fa-solid fa-square"></i>
            </span>
          </button>
        </div>
      </div>

      <div v-if="listGrid">
        <ListeMobileList :msg="biens.datas"/>
        <el-pagination
          style="margin-bottom: 70px"
          :current-page="biens.rpage"
          :page-size="10"
          :page-count="biens.total_pages"
          layout="prev, pager, next"
          @current-change="handlePageChange"
          background
        ></el-pagination>
      </div>
      <div v-else>
        <ListeMobileGrid :msg="biens.datas"/>
        <el-pagination
          style="margin-bottom: 70px"
          :current-page="biens.rpage"
          :page-size="10"
          :page-count="biens.total_pages"
          layout="prev, pager, next"
          @current-change="handlePageChange"
          background
        ></el-pagination>
      </div>
      
      <BottomBar />
    </div>
  </div>
</template>

<script>
import { ElLoading } from 'element-plus'
import NavbarMobile from '@/components/accueil/accueil_mobile/Navbar.vue'
import ListeMobileGrid from '@/components/accueil/accueil_mobile/Liste.vue'
import ListeMobileList from '@/components/accueil/accueil_mobile/Liste_list.vue'
import BottomBar from '@/components/accueil/accueil_mobile/Bottom_bar.vue';
import Searchbar from '@/components/accueil/accueil_mobile/SearchBar.vue';

export default {
  name: 'AccueilIndex',
  components: {
    NavbarMobile,
    ListeMobileList,
    ListeMobileGrid,
    BottomBar,
    Searchbar
  },
  computed: {
    listGrid() {
      return this.$store.state.listGrid;
    },
    biens() {
      return this.$store.state.biens;
    },
  },
  data() {
    return {
      searchOk: false,
      total_row: 0,
      total_pages: 0,
      page: 1,
      datas: [],
      value1: [1, 80],
      select1: '0',
      select2: '0',
      selectedType: '0',
      filters: {
        piscine: '',
        restaurant: '',
        bordureMer: '',
        evenement: '',
        internet: '',
      },
    };
  },
  methods: {
    handlePageChange(page) {
      this.$store.commit('setBiensPage', page);
      this.biens.rpage = page;
      this.ListeFiltre2();
      this.scrollToTop();
      this.$router.go();
    },
    scrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth' // Permet un défilement fluide
			});
		},
    ListeFiltre2() {
      const loading = ElLoading.service({
          lock: true,
          text: 'Loading...',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      const postData = {
        prix_min: this.value1[0] * 5000,
        prix_max: this.value1[1] * 5000,
        type: this.selectedType,
        ville: this.biens.rville,
        parpage: 10,
        commune: this.biens.rquartier,
        piscine: this.filters.piscine ? 1 : 0,
        restaurant: this.filters.restaurant ? 1 : 0,
        bordureMer: this.filters.bordureMer ? 1 : 0,
        evenement: this.filters.evenement ? 1 : 0,
        internet: this.filters.internet ? 1 : 0
      };

      if (postData.prix === 'Tarif') {
        postData.prix = '0';
      }

      if (postData.type === 'Types') {
        postData.type = '0';
      }

      const randomId = Math.floor(Math.random() * 1000000);
      this.$axios.post('/web_liste_appart.php?page='+ this.biens.rpage+'&id='+ randomId, postData)
        .then(response => {
          this.searchOk = true;
          this.datas= response.data.data;
          let data = {
            datas: response.data.data,
            total_row: response.data.total_rows,
            total_pages: response.data.total_pages,
            rtype: this.biens.rtype,
            rville: this.biens.rville,
            rquartier: this.biens.rquartier,
            rpage: this.biens.rpage
          }
          this.$store.commit('setDatas', data);
          let dons = response.data.data[0];
          this.$store.commit("setDonneesBien", dons);
        })
        .catch(error => {
          console.error('Erreur de requête:', error);
        });
        loading.close();
    },

    gridChange(etat) {
      const grid = !etat;
      this.$store.commit('setListGrid', grid);
    }
  },
  mounted() {
    this.ListeFiltre2();
  }
};
</script> 

<style scoped>
/* Styles spécifiques au composant */
.fixebar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.list-container {
  margin-top: 70px;
  position: relative;
  z-index: 900;
}

.results-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.results-count {
  font-size: 1rem;
}

.view-buttons {
  display: flex;
}

.button {
  margin-left: 5px;
}

.fixed-center-right {
  position: fixed;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
</style>
