<template>
<div>
  <div v-if="login" style="background: #00000054; bottom: 0; left: 0; right: 0; position: fixed; z-index: 1000; margin-bottom: -1px; height: 100vh" class="card" @click="closeLogin">
    <div>lala</div>
    <Login v-if="loginPage" class="card" style="background-color: white; bottom: 0; left: 0; right: 0; position: fixed;" @click.stop/>
    <Inscription v-else class="card" style="background-color: white; bottom: 0; left: 0; right: 0; position: fixed;" @click.stop/>
  </div>
</div>
  <router-view/>
</template>
<script>
import Login from '@/components/dashboard/dashboard_login/LoginIndex.vue'
import Inscription from '@/components/dashboard/dashboard_login/InscriptionIndex.vue'
  export default {
    name: 'SummaryPage',
    components: {
    Login,
    Inscription
    }, 
    computed: {
      login() {
        return this.$store.state.loginActive;
      },
      loginPage() {
        return this.$store.state.LoginPage;
      },
    },
    props: {
      web: {
        type: Number, // Indique si la propriété est requise
      }
    },
    data() {
      return {}
    },
    methods: {
      closeLogin() {
        let statut = false;
        this.$store.commit('setLogin', statut);
      },
      addMetaTags() {
          const head = document.head;
          const title = document.createElement('meta');
          title.setAttribute('property', 'og:title');
          title.setAttribute('content', 'SAREZIDE');
          head.appendChild(title);

          const description = document.createElement('meta');
          description.setAttribute('property', 'og:description');
          description.setAttribute('content', 'Description de votre page');
          head.appendChild(description);

          const image = document.createElement('meta');
          image.setAttribute('property', 'og:image');
          image.setAttribute('content', 'https://prod-saint-gobain-fr.content.saint-gobain.io/sites/saint-gobain.fr/files/2022-04/maison-contemporaine-la-maison-saint-gobain01.jpg');
          head.appendChild(image);

          const url = document.createElement('meta');
          url.setAttribute('property', 'og:url');
          url.setAttribute('content', window.location.href);
          head.appendChild(url);
        }
    },
    mounted() {
      this.addMetaTags(); // Appel de la méthode pour ajouter les métadonnées
    },
  }
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 19px;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.card {
  z-index: 1000; /* Mettre la carte au premier plan */
  position: relative; /* S'assurer que la carte est positionnée correctement */
}

.page-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
