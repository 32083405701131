import { getResidenceUser, deleteResidence, getReservationsUser, getReservationsProp, getReservationsAdmin, getResidenceFavoris, postReservation, updateReservation, postFavoris, deleteFavoris, postLogin, postInscription, postAgent, postQuartier, getQuartier, getQuartier2, getAvis, postAvis, putProfile, updateImage, updateImage2, } from './api';

export default {
  install(app) {
    app.config.globalProperties.$api = {
      getResidenceUser,
      deleteResidence,
      getReservationsUser,
      getReservationsProp,
      getReservationsAdmin,
      getResidenceFavoris,
      postReservation,
      updateReservation,
      postFavoris,
      deleteFavoris,
      postLogin,
      postInscription,
      putProfile,
      postAgent,
      postQuartier,
      getQuartier,
      getQuartier2,
      getAvis,
      postAvis,
      updateImage,
      updateImage2,
      // Ajoutez d'autres méthodes API ici
    };
  },
};