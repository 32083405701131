import axios from './axios';

const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < 5; i++) {
      randomString += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return randomString;
  };
  export const getResidenceUser = (userId) => {
    const randomParam = generateRandomString();
    return axios.get(`/get_residence_user.php?user_id=${userId}&random=${randomParam}`);
  };

  export const getResidenceFavoris = (userId) => {
    const randomParam = generateRandomString();
    return axios.get(`/get_residence_favoris.php?user_id=${userId}&random=${randomParam}`);
  };

  export const deleteResidence = (userId) => {
    const randomParam = generateRandomString();
    return axios.get(`/delete_residence.php?residence_id=${userId}&random=${randomParam}`);
  };

  export const getReservationsUser = (userId) => {
    const randomParam = generateRandomString();
    return axios.get(`/get_reservation_user.php?user_id=${userId}&random=${randomParam}`);
  };
  export const getReservationsProp = (userId) => {
    const randomParam = generateRandomString();
    return axios.get(`/get_reservation_prop.php?user_id=${userId}&random=${randomParam}`);
  };

  export const getReservationsAdmin = (userId) => {
    const randomParam = generateRandomString();
    return axios.get(`/get_reservation_admin.php?user_id=${userId}&random=${randomParam}`);
  };

  export const getAvis = (userId) => {
    const randomParam = generateRandomString();
    return axios.get(`/avis_residence.php?resid=${userId}&random=${randomParam}`);
  };

  export const getQuartier = () => {
    const randomParam = generateRandomString();
    return axios.get(`/select_quartier.php?random=${randomParam}`);
  };

  export const getQuartier2 = () => {
    const randomParam = generateRandomString();
    return axios.get(`/select_quartier2.php?random=${randomParam}`);
  };

  export const postReservation = (reservationData) => {
    return axios.post('/insert_reservation.php', reservationData);
  };

  export const updateReservation = (reservationData) => {
    return axios.post('/update_reservation.php', reservationData);
  };

  export const updateImage = (imageData) => {
    return axios.post('/update_image.php', imageData);
  };

  export const updateImage2 = (imageData) => {
    return axios.put('/update_image.php', imageData);
  };

  export const postFavoris = (favorisData) => {
    return axios.post('/insert_favoris.php', favorisData);
  };

  export const postAvis = (favorisData) => {
    return axios.post('/avis_residence.php', favorisData);
  };

  export const deleteFavoris = (favorisData) => {
    return axios.post('/delete_favoris.php', favorisData);
  };

  export const postLogin = (loginData) => {
    return axios.post('/verify_login.php', loginData);
  };

  export const postInscription = (loginData) => {
    return axios.post('/inscription.php', loginData);
  };

  export const putProfile = (loginData) => {
    return axios.put('/profile_update.php', loginData);
  };

  export const postAgent = (loginData) => {
    return axios.post('/new_agent.php', loginData);
  };

  export const postQuartier = (loginData) => {
    return axios.post('/insert_quartier.php', loginData);
  };