<template>
  <div class="home">
    <AccueilMobile  v-if="$isMobile()" msg="Welcome to Your Vue.js App"/>
    <AccueilWeb v-else msg="Welcome to Your Vue.js App" />
    
  </div>
</template>

<script>
// @ is an alias to /src
import AccueilWeb from '@/components/accueil/accueil_web/AccueilIndex.vue'
import AccueilMobile from '@/components/accueil/accueil_mobile/AccueilIndex.vue'

export default {
  name: 'HomeView',
  components: {
    AccueilWeb,
    AccueilMobile
  },
  data() {
    return {
    }
  }
}
</script>
