<template>
    <div>
      <div style="width: 100%; margin-bottom: -20px">
        <nav class="navbar" style="width: 1300px; margin: -10px auto">
            <nav class="navbar" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                  <a class="navbar-item" href="https://bulma.io">
                    <span class="mt-2 mb-1 ml-4">
                        <router-link to="/" style="margin-left: 10px">
                            <span class="sarezide has-text-primary">Sa</span>
                            <span class="sarezide">Rezide</span>
                        </router-link>
                    </span>
              
                  </a>
              
                  <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                  </a>
                </div>
              
                <div id="navbarBasicExample" class="navbar-menu">
                  <div class="navbar-start">
                    
                    <nav class="navbar">
                        <div class="navbar-menu" :class="{'is-active': isActive}" style="margin-left: 5px;">
                          <div class="navbar-start centered-navbar-start">
                            <div class="field has-addons" style="margin-top: 10px; width: 100%; max-width: 230px; padding: 0px 5px">
                              <p class="control">
                                <a class="button is-static" style="background: white;">
                                  Ville
                                </a>
                              </p>
                              <el-select
                                v-model="biens.rville"
                                placeholder="Please enter a keyword"
                                :loading="loading"
                                style="width: 440px;"
                                size="large"
                                @change="ListeFiltre2()"
                              >
                              <el-option
                                   label="Toutes les villes"
                                  value="0"
                                />
                                <el-option
                                  v-for="(ville, index) in uniqueCities"
                                  :key="index"
                                  :label="ville"
                                  :value="ville"
                                />
                              </el-select>
                            </div>
                            <div class="field has-addons" style="margin-top: 10px; width: 100%; max-width: 230px; padding: 0px 5px">
                              <p class="control">
                                <a class="button is-static" style="background: white;">
                                  Zone
                                </a>
                              </p>
                              <el-select
                                v-model="biens.rquartier"
                                placeholder="Please enter a keyword"
                                :loading="loading"
                                style="width: 440px;"
                                size="large"
                                @change="ListeFiltre2()"
                              >
                              <el-option
                                   label="Toutes les zones"
                                  value="0"
                                />
                                <el-option
                                  v-for="(item, index) in filteredQuartiers"
                                  :key="index"
                                  :label="item.quartier"
                                  :value="item.quartier"
                                />
                              </el-select>
                            </div>
                            <div class="field has-addons" style="margin-top: 10px; width: 100%; max-width: 330px; padding: 0px 5px">
                              <p class="control">
                                <a class="button is-static" style="background: white;">
                                  Période
                                </a>
                              </p>
                              <el-date-picker
                                ref="datePicker"
                                style="margin-left: -10px;"
                                v-model="rechercheInfos.date"
                                type="daterange"
                                range-separator="Au"
                                start-placeholder="Date début"
                                end-placeholder="Date Fin"
                                size="large"
                              />
                            </div>
                          </div>
                        </div>
                      </nav>
                  </div>
              
                  <div class="navbar-end">
                    <div class="navbar-item">
                      <div class="buttons">
                        <a class="button is-primary" @click="OpenAjout">
                          <strong>Ajouter une Résidence</strong>
                        </a>
                        <a class="button is-light" @click="OpenLogin" v-if="users.id_user == null">
                          Connexion
                        </a>
                        <a class="button is-light" @click="OpenDadh" v-else>
                          <el-icon style="color: gray; padding: 5px; font-size: 30px; margin-top: -3px"><HomeFilled /></el-icon> Dashboard
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
        </nav>
      </div>
    </div>
  </template>
  
  <script>
  import { ElLoading } from 'element-plus'
  export default {
    name: 'AccueilNavbar',
    computed: {
      rechercheInfos() {
        return this.$store.state.rechercheInfos;
      },
      uniqueCities() {
        const unique = new Set();
        return this.datasQuartier
          .map(item => item.ville)
          .filter(ville => {
          if (unique.has(ville)) {
            return false;
          } else {
            unique.add(ville);
            return true;
          }
        });
      },
      filteredQuartiers() {
        return this.datasQuartier.filter(item => item.ville === this.biens.rville);
      },
      biens() {
        return this.$store.state.biens;
      },
      users() {
        return this.$store.state.users;
      },
    },
    data() {
      return {
        value1: [1, 80],
        filters: {
        piscine: '',
        restaurant: '',
        bordureMer: '',
        evenement: '',
        internet: '',
      	},
        destination: '',
        datePeriode: [],
        datasQuartier: [],
        isActive: false,
        listZone: [
          { libelle: 'Abidjan Cocody' },
          { libelle: 'Abidjan Abobo' },
          { libelle: 'Abidjan Treichville' },
          { libelle: 'Abidjan Portbouet' },
          { libelle: 'Abidjan Yopougon' },
        ]
      };
    },
    created() {
      this.setDefaultDates(); // Appel à la méthode pour initialiser les dates par défaut
    },
    watch: {
      rechercheInfos: {
        handler() {
          this.searchData(); // Call searchData method whenever rechercheInfos changes
        },
        deep: true, // This ensures deep watching of rechercheInfos properties
      },
    },
    methods: {
      toggleNavbar() {
        this.isActive = !this.isActive;
      },
      OpenLogin() {
        this.$router.push({ path: '/login_web' });
      },
      OpenDadh() {
        this.$router.push({ path: '/dashboard_w' });
      },
      OpenAjout() {
        let mode = true;
        this.$store.commit('setMode', mode);
        this.$router.push({ path: '/nouvelle_enregistrement' });
      },
      fetchQuartier() {
        this.$api.getQuartier2()
          .then(response => {
            this.datasQuartier = response.data.data;
          })
          .catch(error => {
            console.error('Erreur de requête:', error);
          });
      },
      searchData() {
        const info = {
          destination: this.rechercheInfos.destination,
          date: this.rechercheInfos.date,
          adulte: this.rechercheInfos.adulte,
          enfant: this.rechercheInfos.enfant,
        };
        this.$store.commit('setRechercheInfos2', info);
      },
      onDestinationBlur() {
        if (this.destination) {
          this.$refs.datePicker.focus();
        }
      },
      ListeFiltre2() {
        if(this.biens.rville == "0") {
          this.biens.rquartier = "0";
        }
      let dataSearch = {
        ville: this.biens.rville,
        commune: this.biens.rquartier,
        type: this.biens.rtype,
      };
      this.$store.commit('setBiensSearch', dataSearch);
      const loading = ElLoading.service({
              lock: true,
              text: 'Loading...',
          });
      const postData = {
        prix_min: this.value1[0] * 5000,
        prix_max: this.value1[1] * 5000,
        type: this.biens.rtype,
        ville: this.biens.rville,
        parpage: 20,
        commune: this.biens.rquartier,
        piscine: this.filters.piscine ? 1 : 0,
        restaurant: this.filters.restaurant ? 1 : 0,
        bordureMer: this.filters.bordureMer ? 1 : 0,
        evenement: this.filters.evenement ? 1 : 0,
        internet: this.filters.internet ? 1 : 0
      };

      if (postData.prix === 'Tarif') {
        postData.prix = '0';
      }

      if (postData.type === 'Types') {
        postData.type = '0';
      }
      const randomId = Math.floor(Math.random() * 1000000);
        this.$axios.post('/web_liste_appart.php?page='+ this.page +'&id='+ randomId, postData)
          .then(response => {
            //this.searchOk = true;
            let data = {
              datas: response.data.data,
              total_row: response.data.total_rows,
              total_pages: response.data.total_pages,
              rtype: this.biens.rtype,
              rville: this.biens.rville,
              rquartier: this.biens.rquartier,
              rpage: this.biens.rpage
            }
            this.$store.commit('setDatas', data);
          })
          .catch(error => {
            console.error('Erreur de requête:', error);
          });
      loading.close();
    },
    
      setDefaultDates() {
        // Calculer la date de demain et après-demain
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
  
        const dayAfterTomorrow = new Date();
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  
        // Formater les dates au format ISO (YYYY-MM-DD) pour l'élément date-picker
        const tomorrowISO = tomorrow.toISOString().split('T')[0];
        const dayAfterTomorrowISO = dayAfterTomorrow.toISOString().split('T')[0];
  
        // Définir datePeriode avec ces valeurs
        this.rechercheInfos.date = [tomorrowISO, dayAfterTomorrowISO];
      }
    },
    mounted() {
      // Set dateDebut to tomorrow's date
      this.fetchQuartier();
    }
  };
  </script>
  
  <style scoped>
  /* Styles spécifiques au composant */
  .navbar {
    top: 0;
    width: 100%;
    max-width: 1880px;
    margin: 0 auto;
    z-index: 1000;
    background: white;
    padding: 0px;
    padding-top: 6px;
  }
  .navbar-item img {
    width: 150px;
  }
  .responsive-input {
    max-width: 500px;
    width: 100%;
  }
  .centered-navbar-start {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  </style>
  